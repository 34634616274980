<template>
  <div class="nav-list-mobile">
    <div v-if="activeLevel !== 1" class="nav-list-mobile__level1-top-wrapper">
      <div class="nav-list-mobile__title">
        {{ data.title }}
      </div>
      <SlotLink
        v-if="data.url"
        :url="data.url"
        class="nav-list-mobile__link"
        show-chevron
      >
        {{ useTranslation('general', 'discover') }}
      </SlotLink>
    </div>
    <ul v-if="!isBrandsList" class="nav-list-mobile__list">
      <AppNavListItemMobile
        v-for="item, index in data.children"
        :key="index"
        :title="item.title"
        :url="item.url"
        :children="item.children"
        :level="level"
        :uid="item._uid"
        :index="index"
      />
    </ul>
    <BlockBrandsList
      v-else
      class="nav-list-mobile__content-brands"
      :data="data"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    level: {
        type: Number,
        default: 0,
    },
});

const flyoutStore = useFlyoutStore();
const activeLevel = computed(() => flyoutStore.getActiveLevel);

const isBrandsList = computed(() => {
    if (props.data?.brands) {
        return true;
    }
    return false;
});
</script>

<style lang="scss">
.nav-list-mobile__list {
    margin-top: 218px;
    margin-bottom: 41px;

    @include mobile {
        margin-top: 108px;
    }

}

.nav-list-mobile__level1-top-wrapper {
    margin-top: 82px;

    & + .nav-list-mobile__list {
        margin-top: 50px;

        @include mobile {
            margin-top: 25px;
        }
    }

    @include mobile {
        margin-top: 98px;
    }
}

.nav-list-mobile__title {
    @include typo-font('regular');
    @include typo-size('navLevel2');

    color: $C_GREEN_DARK;
}

.nav-list-mobile__link {
    @include typo-font('regular');
    @include typo-size('navLevel3');

    margin-top: 6px;
    color: $C_PRIMARY;
}

.nav-list-mobile__content-brands {
    @include fluid('margin-top', 57px, 80px);

    .block-brands-list__inner {
        @include mobile {
            @include grid-layout(3, 1fr);
            @include fluid('grid-gap', 10px, 34px);
        }
    }

    .atom-heading {
        display: none;
    }
}
</style>
